import React, { useEffect, useState } from 'react';
import { Container, Button, Card, Table, Form } from 'react-bootstrap';
import { Utils } from '@common';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// api
import { getVoucherAssignData, patchVoucherAll } from '@api/eduadmin/voucher';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

export default React.memo(function AdmEduVoucherLookup(props) {
  const history = useHistory();
  const [nth, setNth] = useState();
  const [totalVoucherSum, setTotalVoucherSum] = useState();
  const [voucherList, setVoucherList] = useState([]);
  const [maxVoucherSum, setMaxVoucherSum] = useState(null);
  const [pointSum, setPointSum] = useState(null);

  const getData = async () => {
    let sumMaxVoucher = 0;
    let sumPoint = 0;
    const { data } = await getVoucherAssignData();
    if (data.code === 0) {
      setNth(data.data.nth);
      setTotalVoucherSum(data.data.totalVoucherSum);
      setVoucherList(data.data.list);
      data.data.list.forEach(item => {
        sumMaxVoucher += item.maxVoucher;
        sumPoint += item.pointSum;
      });
      setMaxVoucherSum(sumMaxVoucher);
      setPointSum(sumPoint);
    }
  };

  const onVoucher = async () => {
    const { data } = await patchVoucherAll();
    if (data.code === 0) {
      Swal.fire({
        text: '할당 되었습니다.',
        confirmButtonText: '확인',
      });
      history.goBack();
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <main id="admEdu-voucher-allotment" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>바우처 할당</h5>
            </div>
          </section>

          <Card>
            <Card.Header>
              <div className="btn-area m-0 justify-content-between">
                {nth && (
                  <>
                    <h6 className="text-dark mb-1">{nth}차 진행중</h6>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        onVoucher();
                      }}
                    >
                      할당하기
                    </Button>
                  </>
                )}
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <Table className="bt-none">
                <colgroup>
                  <col width={100} />
                  <col width={260} />
                  <col width={260} />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>학교명</th>
                    <th>실시간 바우처 점수</th>
                    <th className="text-end">예상 할당금액</th>
                    <th className="text-end">학교 최대 배정예산</th>
                  </tr>
                </thead>
              </Table>
              <SimpleBar style={{ maxHeight: 400 }}>
                <Table className="bt-none tr-even-bg" style={{ marginTop: -1 }}>
                  <colgroup>
                    <col width={100} />
                    <col width={260} />
                    <col width={260} />
                    <col width="*" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    {voucherList.length > 0 ? (
                      voucherList.map(item => {
                        return (
                          <tr key={`school-${item.schoolId}`}>
                            <td>{item.schoolId}</td>
                            <td>{item.schoolName}</td>
                            <td>{Utils.numberComma(item.pointSum)}</td>
                            <td className="text-end">
                              {Utils.numberComma(item.voucherSum)}
                            </td>
                            <td className="text-end">
                              {Utils.numberComma(item.maxVoucher)}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <p className="no-data">데이터가 없습니다.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </SimpleBar>
              <Table
                className="bt-none tr-even-bg table-total"
                style={{ marginTop: -1, zIndex: 2 }}
              >
                <colgroup>
                  <col width={100} />
                  <col width={260} />
                  <col width={260} />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <td>합계</td>
                    <td />
                    <td>{Utils.numberComma(pointSum)}</td>
                    <td className="text-end">
                      {Utils.numberComma(totalVoucherSum)}
                    </td>
                    <td className="text-end">
                      {Utils.numberComma(maxVoucherSum)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});

const dataDummy = [
  {
    id: 510,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 509,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 508,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 507,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 506,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 505,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 504,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 503,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 502,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 501,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
  {
    id: 500,
    school: '부산진여자상업고등학교',
    score: 2341,
  },
];
